:root {
  --charcoal: rgb(38, 40, 43);
}

body {
  margin: 0;
  background-color: white;
}

#website-body {
  max-width: 1500px;
  margin: auto;
}

.wrapper-content {
  width: 100%;
  height: 100%;
}

.navbar {
  width: 100%;
  height: auto;
  background-color: var(--charcoal);
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.navitem {
  height: 80%;
  width: clamp(115px, 10rem, 11rem);
  text-align: center;
  padding-top: .2rem;
  padding-bottom: .6rem;
  margin-top: .2rem;
  /* padding-left: 1.5rem;
  padding-right: 1.5rem;; */
}

.navitem>a {
  color: whitesmoke;
  font-family: serif;
  font-size: 1.4rem;
}

@media (max-width: 450px) {
  .navitem>a {
    font-size: 1.15rem;
  }
}

.navitem>a:hover,
.navitem>a:focus,
.active {
  color: goldenrod
}

.invertColor {
  filter: invert(100%);
}

.profileImg {
  width: clamp(150px, 15%, 225px);
  height: auto;
  display: block;
  margin: 0 auto 0 auto;
  border: 3px solid var(--charcoal);
  border-radius: 50%;

}

#imgWrapper {
  width: 100%;
  display: inline-block;
}

#banner {
  background: var(--charcoal);

}

#spacer {
  height: 10vh;
  width: 100%;
  background-color: white;
  overflow: visible;
  display: inline-block;
  padding-top: 10px;
}

#footer {
  width: 100%;
  text-align: center;
  font-size: 0.75rem;
  color: transparent;
  padding-top: 0.15rem;

  position: fixed;
  bottom: 0px;
  left: 0px;
}

#socials-wrapper {
  background-color: var(--charcoal);
  color: whitesmoke;
  padding-bottom: 0.5rem;
}

#socials-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: clamp(200px, 40%, 700px);
  margin: auto;
  border-top: 2px outset whitesmoke;
}

#socials-container>* {
  width: 25%;
  margin-top: 0.4rem;
}

#socials-container>* a {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  text-decoration: none;
  color: whitesmoke;
}

#socials-container>* img {
  max-width: 30px;
  height: auto;
  margin: auto;
}

#socials-container>* p {
  margin: auto;
  display: inline;
  padding-top: 0.1rem;
}

@media only screen and (max-width: 800px) {
  #socials-container>* p {
    display: none;
  }
}