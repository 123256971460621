:root {
  --charcoal:rgb(38, 40, 43);
}

#page-content {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap-reverse;
  justify-content: center;
}

.column {
  width: clamp(450px, 47%, 50%);
  padding-left: .5rem;
  padding-right: .5rem;
  margin-bottom: 1rem;
}

#download-container {
  margin-top: 0.2rem;
}

.resumeEntry {
  width: 100%;
  height: auto;
  display: inline-flex;
  flex-direction: row;
  align-content: center;
  flex-wrap: nowrap;
  margin-top: 0.5rem;
}

.resumeEntryContainer{
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  width: clamp(15rem,100%, 750px);
}

#RightColumn .resumeEntryBody {
  margin-top: 0;
}

.resumeEntryBody{
  margin-top: 0.5rem;
  width: 100%;
}

/* .resumeEntryContainer > .resumeEntryTitle {
  margin-bottom: 0.5rem;
} */
.resumeEntryTitle {
  font-weight: bolder;
  font-size: 1.15rem;
}

.resumeEntryContent {
  margin-top: 0.1rem;
  padding-left: 1.5rem;
}

.educationEntryTitle {
  font-style: italic;
  font-weight: bolder;
  font-size: 1.15rem;
}

.educationEntryContent {
  margin-top: 0.1rem;
}

.educationEntryContent ul{
  margin-top: 0.15rem;
  margin-bottom: 0;
}

.educationEntryContent li {
  list-style-type: "➢\2002";
  width: 95%;
  margin-bottom: 0.4rem
}

.jobEntryTitle {
  font-style: italic;
  font-weight: bold;
  font-size: 1.02rem;
  overflow-wrap: normal;
}

.jobEntryContent {
  margin-top: 0.1rem;
}

.jobEntryContent ul{
  margin-top: 0.15rem;
  margin-bottom: 0;
}

.jobEntryContent li {
  list-style-type: "➢\2002";
  width: 95%;
}


.companyLogo {
  width: clamp(6rem, 8%, 300px);
  margin: 1rem;
  display: flex;
  justify-content: center;
  flex-shrink: 0;
}

.companyLogo > img {
  max-width: 100%;
  width: clamp(6rem,100%,max-content);
  height: max-content;
  max-height: clamp(100px,auto,100%);
  display: block;
  align-self: start;
}

.resume h1 {
  text-decoration: underline;
  margin-bottom: 0;
  margin-top: 0rem;
}

#SocitiesHeading {
  margin-top: 1rem;
}

@media (max-width: 450px){
  .resumeEntry {
    justify-content: center;
    flex-wrap: wrap;
  }
  .resumeEntryContainer{
    width: 90%;
    text-align: center;
    justify-content: center;
  }

  .jobEntryContent {
    text-align: left;
    padding: 0;
    width: 100%;
  }

  li {
    margin-bottom: 0.2rem;
  }

  .companyLogo > img {
    max-width: 100%;
    width: 80vw;
    height: auto;
    max-height: clamp(100px,max-content,100%);
    display: block;
    align-self: start;
  }

  .resume h1 {
    text-align: center;
    text-decoration: none;
  }

  .resume h1::before,
  .resume h1::after {
    content: '';
    display: block;
    border: 1px solid black;
  }
}

@media screen and (orientation:landscape){
  .companyLogo > img {
    max-width: 100%;
    width: 80vw;
    height: auto;
    max-height: clamp(100px,max-content,auto);
    display: block;
    align-self: start;
  }
}