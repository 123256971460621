:root {
  --charcoal:rgb(38, 40, 43);
}

.page-content {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
}

button {
  width: clamp(10em, 25%, 80%);
  height: 2rem;
  border-radius: 0.5rem;
  background-color: whitesmoke;
  border: 3px var(--charcoal) inset;
}

.home-button {
  text-decoration: none;
  color: var(--charcoal);
}

button:hover,
button:hover > *,
.home-button:hover{
  cursor: pointer;
  color: goldenrod;
  text-decoration: underline;
}
